import { type PageContent } from '../types';

export const cookiesContent: PageContent = {
  en: `
    <h1>Cookie Policy</h1>
    <p>This policy explains how TimeHub.info uses cookies and similar technologies to provide and improve our services.</p>
  `,
  es: `
    <h1>Política de Cookies</h1>
    <p>Esta política explica cómo TimeHub.info utiliza cookies y tecnologías similares para proporcionar y mejorar nuestros servicios.</p>
  `,
  zh: `
    <h1>Cookie 政策</h1>
    <p>本政策说明 TimeHub.info 如何使用 cookie 和类似技术来提供和改进我们的服务。</p>
  `,
  ar: `
    <h1>سياسة ملفات تعريف الارتباط</h1>
    <p>توضح هذه السياسة كيف يستخدم TimeHub.info ملفات تعريف الارتباط والتقنيات المماثلة لتقديم وتحسين خدماتنا.</p>
  `,
  bn: `
    <h1>কুকি নীতি</h1>
    <p>এই নীতি ব্যাখ্যা করে কিভাবে TimeHub.info আমাদের পরিষেবা প্রদান এবং উন্নত করতে কুকি এবং অনুরূপ প্রযুক্তি ব্যবহার করে।</p>
  `,
  pt: `
    <h1>Política de Cookies</h1>
    <p>Esta política explica como o TimeHub.info usa cookies e tecnologias semelhantes para fornecer e melhorar nossos serviços.</p>
  `,
  ru: `
    <h1>Политика использования файлов cookie</h1>
    <p>Эта политика объясняет, как TimeHub.info использует файлы cookie и подобные технологии для предоставления и улучшения наших услуг.</p>
  `,
  ja: `
    <h1>Cookie ポリシー</h1>
    <p>このポリシーでは、TimeHub.info がサービスの提供と改善のために Cookie や類似の技術をどのように使用しているかを説明します。</p>
  `,
  fr: `
    <h1>Politique des Cookies</h1>
    <p>Cette politique explique comment TimeHub.info utilise les cookies et les technologies similaires pour fournir et améliorer nos services.</p>
  `,
  tr: `
    <h1>Çerez Politikası</h1>
    <p>Bu politika, TimeHub.info'nun hizmetlerimizi sağlamak ve iyileştirmek için çerezleri ve benzer teknolojileri nasıl kullandığını açıklar.</p>
  `
};