import { type PageContent } from '../types';

export const privacyContent: PageContent = {
  en: `
    <h1>Privacy Policy</h1>
    <p>At TimeHub.info, we take your privacy seriously. This policy outlines how we collect, use, and protect your data.</p>
  `,
  es: `
    <h1>Política de Privacidad</h1>
    <p>En TimeHub.info, nos tomamos su privacidad muy en serio. Esta política describe cómo recopilamos, usamos y protegemos sus datos.</p>
  `,
  zh: `
    <h1>隐私政策</h1>
    <p>在 TimeHub.info，我们严肃对待您的隐私。本政策概述了我们如何收集、使用和保护您的数据。</p>
  `,
  ar: `
    <h1>سياسة الخصوصية</h1>
    <p>في TimeHub.info، نأخذ خصوصيتك على محمل الجد. توضح هذه السياسة كيفية جمع واستخدام وحماية بياناتك.</p>
  `,
  bn: `
    <h1>গোপনীয়তা নীতি</h1>
    <p>TimeHub.info-এ আমরা আপনার গোপনীয়তাকে গুরুত্বের সাথে নিই। এই নীতি আমরা কীভাবে আপনার তথ্য সংগ্রহ, ব্যবহার এবং সুরক্ষা করি তা বর্ণনা করে।</p>
  `,
  pt: `
    <h1>Política de Privacidade</h1>
    <p>No TimeHub.info, levamos sua privacidade a sério. Esta política descreve como coletamos, usamos e protegemos seus dados.</p>
  `,
  ru: `
    <h1>Политика конфиденциальности</h1>
    <p>В TimeHub.info мы серьезно относимся к вашей конфиденциальности. Эта политика описывает, как мы собираем, используем и защищаем ваши данные.</p>
  `,
  ja: `
    <h1>プライバシーポリシー</h1>
    <p>TimeHub.infoでは、お客様のプライバシーを重視しています。このポリシーでは、お客様のデータの収集、使用、保護方法について説明します。</p>
  `,
  fr: `
    <h1>Politique de Confidentialité</h1>
    <p>Chez TimeHub.info, nous prenons votre vie privée au sérieux. Cette politique décrit comment nous collectons, utilisons et protégeons vos données.</p>
  `,
  tr: `
    <h1>Gizlilik Politikası</h1>
    <p>TimeHub.info'da gizliliğinizi ciddiye alıyoruz. Bu politika, verilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu açıklar.</p>
  `
};