import { aboutContent } from './content/about';
import { contactContent } from './content/contact';
import { privacyContent } from './content/privacy';
import { cookiesContent } from './content/cookies';

export const pageContent = {
  about: aboutContent,
  contact: contactContent,
  privacy: privacyContent,
  cookies: cookiesContent
};