import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DarkModeState {
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
}

export const useDarkMode = create<DarkModeState>()(
  persist(
    (set) => ({
      darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
      setDarkMode: (value) => {
        set({ darkMode: value });
        if (value) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      },
    }),
    {
      name: 'dark-mode-storage',
    }
  )
);

// Initialize dark mode on app load
if (typeof window !== 'undefined') {
  const darkMode = useDarkMode.getState().darkMode;
  if (darkMode) {
    document.documentElement.classList.add('dark');
  }
}