import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  
  const getLocalizedPath = (path: string) => {
    return i18n.language === 'en' ? path : `/${i18n.language}${path}`;
  };
  
  return (
    <footer className="mt-auto py-8 bg-white dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <nav className="flex justify-center space-x-6">
          <Link
            to={getLocalizedPath('/about')}
            className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
          >
            {t('footer.about')}
          </Link>
          <Link
            to={getLocalizedPath('/contact')}
            className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
          >
            {t('footer.contact')}
          </Link>
          <Link
            to={getLocalizedPath('/privacy')}
            className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
          >
            {t('footer.privacy')}
          </Link>
          <Link
            to={getLocalizedPath('/cookies')}
            className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
          >
            {t('footer.cookies')}
          </Link>
        </nav>
        <p className="text-center text-sm text-gray-500 dark:text-gray-400 mt-4">
          © {new Date().getFullYear()} TimeHub.info. {t('footer.rights')}
        </p>
      </div>
    </footer>
  );
};