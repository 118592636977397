import { type PageContent } from '../types';

export const contactContent: PageContent = {
  en: `
    <h1>Contact Us</h1>
    <p>We'd love to hear from you! Get in touch with us for any questions, suggestions, or support needs.</p>
    <h2>Email</h2>
    <p>support@timehub.info</p>
  `,
  es: `
    <h1>Contáctenos</h1>
    <p>¡Nos encantaría saber de ti! Ponte en contacto con nosotros para cualquier pregunta, sugerencia o necesidad de soporte.</p>
    <h2>Correo electrónico</h2>
    <p>support@timehub.info</p>
  `,
  zh: `
    <h1>联系我们</h1>
    <p>我们很乐意听到您的声音！如有任何问题、建议或需要支持，请与我们联系。</p>
    <h2>电子邮件</h2>
    <p>support@timehub.info</p>
  `,
  ar: `
    <h1>اتصل بنا</h1>
    <p>يسعدنا أن نسمع منك! تواصل معنا لأي أسئلة أو اقتراحات أو احتياجات دعم.</p>
    <h2>البريد الإلكتروني</h2>
    <p>support@timehub.info</p>
  `,
  bn: `
    <h1>যোগাযোগ করুন</h1>
    <p>আপনার থেকে শুনতে আমরা আগ্রহী! যেকোনো প্রশ্ন, পরামর্শ বা সহায়তার প্রয়োজনে আমাদের সাথে যোগাযোগ করুন।</p>
    <h2>ইমেইল</h2>
    <p>support@timehub.info</p>
  `,
  pt: `
    <h1>Entre em Contato</h1>
    <p>Adoraríamos ouvir você! Entre em contato conosco para quaisquer perguntas, sugestões ou necessidades de suporte.</p>
    <h2>E-mail</h2>
    <p>support@timehub.info</p>
  `,
  ru: `
    <h1>Свяжитесь с нами</h1>
    <p>Мы будем рады услышать вас! Свяжитесь с нами по любым вопросам, предложениям или потребностям в поддержке.</p>
    <h2>Электронная почта</h2>
    <p>support@timehub.info</p>
  `,
  ja: `
    <h1>お問い合わせ</h1>
    <p>ご連絡をお待ちしております！ご質問、ご提案、サポートが必要な場合はお気軽にお問い合わせください。</p>
    <h2>メール</h2>
    <p>support@timehub.info</p>
  `,
  fr: `
    <h1>Contactez-nous</h1>
    <p>Nous aimerions avoir de vos nouvelles ! Contactez-nous pour toute question, suggestion ou besoin d'assistance.</p>
    <h2>Email</h2>
    <p>support@timehub.info</p>
  `,
  tr: `
    <h1>Bize Ulaşın</h1>
    <p>Sizden haber almak isteriz! Herhangi bir soru, öneri veya destek ihtiyacı için bizimle iletişime geçin.</p>
    <h2>E-posta</h2>
    <p>support@timehub.info</p>
  `
};