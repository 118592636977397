import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePageContent } from '@/hooks/usePageContent';

export const Contact: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { content } = usePageContent();
  
  const pageContent = content.contact?.[i18n.language] || t('contact.placeholder');

  return (
    <div className="max-w-3xl mx-auto">
      <div 
        className="prose dark:prose-invert max-w-none"
        dangerouslySetInnerHTML={{ __html: pageContent }}
      />
    </div>
  );
};