import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { FullScreenClock } from './FullScreenClock';

interface PageLayoutProps {
  children: React.ReactNode;
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
  is24Hour: boolean;
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  darkMode,
  setDarkMode,
  is24Hour,
  isFullScreen,
  setIsFullScreen,
}) => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors flex flex-col">
      {isFullScreen && (
        <FullScreenClock
          is24Hour={is24Hour}
          onClose={() => setIsFullScreen(false)}
        />
      )}
      
      <div className="container mx-auto px-4 py-8 flex-grow">
        <Header
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          setIsFullScreen={setIsFullScreen}
        />
        {children}
      </div>
      <Footer />
    </div>
  );
};