import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageLayout } from './components/PageLayout';
import { MainContent } from './components/MainContent';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Privacy } from './pages/Privacy';
import { Cookies } from './pages/Cookies';
import { useDarkMode } from './hooks/useDarkMode';

const App: React.FC = () => {
  const { darkMode, setDarkMode } = useDarkMode();
  const [is24Hour, setIs24Hour] = React.useState(true);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const { i18n } = useTranslation();

  // Common layout props
  const layoutProps = {
    darkMode,
    setDarkMode,
    is24Hour,
    isFullScreen,
    setIsFullScreen,
  };

  return (
    <Router>
      <Routes>
        {/* Default routes */}
        <Route
          path="/"
          element={
            <PageLayout {...layoutProps}>
              <MainContent is24Hour={is24Hour} setIs24Hour={setIs24Hour} />
            </PageLayout>
          }
        />
        <Route
          path="/about"
          element={
            <PageLayout {...layoutProps}>
              <About />
            </PageLayout>
          }
        />
        <Route
          path="/contact"
          element={
            <PageLayout {...layoutProps}>
              <Contact />
            </PageLayout>
          }
        />
        <Route
          path="/privacy"
          element={
            <PageLayout {...layoutProps}>
              <Privacy />
            </PageLayout>
          }
        />
        <Route
          path="/cookies"
          element={
            <PageLayout {...layoutProps}>
              <Cookies />
            </PageLayout>
          }
        />

        {/* Localized routes */}
        {Object.keys(i18n.options.resources || {}).map((lang) => (
          <React.Fragment key={lang}>
            <Route
              path={`/${lang}`}
              element={
                <PageLayout {...layoutProps}>
                  <MainContent is24Hour={is24Hour} setIs24Hour={setIs24Hour} />
                </PageLayout>
              }
            />
            <Route
              path={`/${lang}/about`}
              element={
                <PageLayout {...layoutProps}>
                  <About />
                </PageLayout>
              }
            />
            <Route
              path={`/${lang}/contact`}
              element={
                <PageLayout {...layoutProps}>
                  <Contact />
                </PageLayout>
              }
            />
            <Route
              path={`/${lang}/privacy`}
              element={
                <PageLayout {...layoutProps}>
                  <Privacy />
                </PageLayout>
              }
            />
            <Route
              path={`/${lang}/cookies`}
              element={
                <PageLayout {...layoutProps}>
                  <Cookies />
                </PageLayout>
              }
            />
          </React.Fragment>
        ))}
      </Routes>
    </Router>
  );
};

export default App;