import React from 'react';
import { useTranslation } from 'react-i18next';
import { Languages } from 'lucide-react';
import { languages } from '../i18n';
import { useLocation, useNavigate } from 'react-router-dom';

export const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
    setIsOpen(false);

    const pathSegments = location.pathname.split('/');
    const currentLang = pathSegments[1];
    
    if (Object.keys(languages).includes(currentLang)) {
      // If current path has a language code, replace it
      pathSegments[1] = code;
      navigate(pathSegments.join('/'));
    } else {
      // If no language code in path, add it (except for English which is default)
      if (code === 'en') {
        navigate(location.pathname);
      } else {
        navigate(`/${code}${location.pathname}`);
      }
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
      >
        <Languages className="w-5 h-5" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-lg bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1">
            {Object.entries(languages).map(([code, { nativeName }]) => (
              <button
                key={code}
                onClick={() => handleLanguageChange(code)}
                className={`block w-full px-4 py-2 text-sm text-left ${
                  i18n.language === code
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {nativeName}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};