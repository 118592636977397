import React from 'react';
import { Clock } from './Clock';
import { WorldClocks } from './WorldClocks';
import { AlarmList } from './AlarmList';
import { AddAlarm } from './AddAlarm';
import { AlarmSound } from './AlarmSound';
import { Stopwatch } from './Stopwatch';
import { CountdownTimer } from './CountdownTimer';
import { PomodoroTimer } from './PomodoroTimer';
import { useTranslation } from 'react-i18next';

type Tab = 'alarms' | 'stopwatch' | 'timer' | 'pomodoro';

interface MainContentProps {
  is24Hour: boolean;
  setIs24Hour: (value: boolean) => void;
}

export const MainContent: React.FC<MainContentProps> = ({ is24Hour, setIs24Hour }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<Tab>('alarms');

  const TabButton: React.FC<{ tab: Tab; current: Tab; onClick: () => void; children: React.ReactNode }> = ({
    tab,
    current,
    onClick,
    children
  }) => (
    <button
      onClick={onClick}
      className={`px-2.5 py-1.5 text-sm font-medium rounded-lg transition-colors whitespace-nowrap ${
        tab === current
          ? 'bg-blue-500 text-white'
          : 'text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700'
      }`}
    >
      {children}
    </button>
  );

  return (
    <main className="flex flex-col items-center space-y-8">
      <Clock
        is24Hour={is24Hour}
        onToggleFormat={() => setIs24Hour(!is24Hour)}
      />
      
      <WorldClocks is24Hour={is24Hour} />
      
      <div className="w-full max-w-md">
        <div className="flex flex-wrap justify-center gap-1.5 mb-6 bg-gray-100 dark:bg-gray-800 p-1.5 rounded-lg">
          <TabButton tab="alarms" current={activeTab} onClick={() => setActiveTab('alarms')}>
            {t('tabs.alarms')}
          </TabButton>
          <TabButton tab="stopwatch" current={activeTab} onClick={() => setActiveTab('stopwatch')}>
            {t('tabs.stopwatch')}
          </TabButton>
          <TabButton tab="timer" current={activeTab} onClick={() => setActiveTab('timer')}>
            {t('tabs.timer')}
          </TabButton>
          <TabButton tab="pomodoro" current={activeTab} onClick={() => setActiveTab('pomodoro')}>
            {t('tabs.pomodoro')}
          </TabButton>
        </div>

        {activeTab === 'alarms' && (
          <>
            <AddAlarm />
            <div className="mt-6">
              <AlarmList />
            </div>
          </>
        )}
        {activeTab === 'stopwatch' && <Stopwatch />}
        {activeTab === 'timer' && <CountdownTimer />}
        {activeTab === 'pomodoro' && <PomodoroTimer />}
      </div>
      
      <AlarmSound />
    </main>
  );
};