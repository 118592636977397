import { pageContent } from '../editthis/pageContent';

interface PageContent {
  [page: string]: {
    [lang: string]: string;
  };
}

export const usePageContent = () => {
  return {
    content: pageContent as PageContent,
    loadContent: () => {}, // No-op since content is static
    updateContent: async (_page: string, _lang: string, _content: string) => {
      // No-op since we're using static content
    },
    saving: false
  };
};