import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Moon, Sun, Maximize2, ArrowLeft } from 'lucide-react';
import { LanguageSelector } from './LanguageSelector';

interface HeaderProps {
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
  setIsFullScreen: (value: boolean) => void;
}

export const Header: React.FC<HeaderProps> = ({
  darkMode,
  setDarkMode,
  setIsFullScreen,
}) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  
  const isHomePage = location.pathname === '/' || location.pathname === `/${i18n.language}`;
  const isInfoPage = /\/(about|contact|privacy|cookies)/.test(location.pathname);
  
  const getLocalizedPath = (path: string) => {
    return i18n.language === 'en' ? path : `/${i18n.language}${path}`;
  };

  return (
    <header className="flex justify-between items-center mb-8">
      <div className="flex items-center space-x-4">
        {!isHomePage && isInfoPage && (
          <Link
            to={getLocalizedPath('/')}
            className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            title={t('app.backToHome')}
          >
            <ArrowLeft className="w-5 h-5" />
          </Link>
        )}
        <Link to={getLocalizedPath('/')} className="text-lg sm:text-xl font-bold text-gray-900 dark:text-white">
          {t('app.title')}
        </Link>
      </div>
      <div className="flex space-x-2">
        <LanguageSelector />
        <button
          onClick={() => setIsFullScreen(true)}
          className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
          title={t('app.fullScreen')}
        >
          <Maximize2 className="w-5 h-5" />
        </button>
        <button
          onClick={() => setDarkMode(!darkMode)}
          className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
          title={t('app.darkMode')}
        >
          {darkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
        </button>
      </div>
    </header>
  );
};